import React from 'react';
import './App.css';
import contactsBackground from './contactsBackground.svg'
import logoHeader from './warehousefinLogoFull.svg'

function App() {
  return (
    <div className="App">



      <header className = "App-header">

      <div className = "background">
      <img className = "contactBackground" src = {contactsBackground} alt = "background"/>
      <img className = "logoHeader" src = {logoHeader} alt = "logo"/>
      <div className = "contactDetails">
      <h2>Yhteystiedot</h2>
      <p>Tina Jämsén <br/> +358 40 552 2482</p>
      <p>Jaakko Niemelä <br/> +358 40 557 8189</p>
      <p>warehousefin@sennek.fi</p>
      <h2>Aukioloajat</h2>
      <p>Ma-Pe 8-16</p>
    
      </div>
      

      </div>
      </header>


   
 

    </div>
  );
}

export default App;
